.App {
    text-align: center;
    height: 100%;
}

.full-height {
    height: 100%;
}

.logo {
    padding: 13px 17px 0 10px;
}

.local-navbar {
    margin-bottom: 0;
    min-height: 40px;
}

.main {
    padding: 0;
    height: 100%;
}

.left-panel {
    position: absolute;
    top: 0;
    left: 0;
}

.col-sm-12 {
    padding: 0;
}

.overlay-container {
    overflow: auto;
    position: relative;
}

.container {
    padding: 0;
    margin: 0;
}

.sidepanel {
    width: 43%;
    max-width: 1600px;
    min-width: 230px;
    float: left; /* [1] */
    border-right: 1px solid #ddd;
}


