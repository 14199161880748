#navbar-brand-landing {
    max-width: 15em;
    margin-top: 2px;
}

.navbar {
    padding: 0.3rem 1rem !important;
    z-index: 20;
}

.navbar-shadow {
    box-shadow: 0 0 0.4em 0 rgba(0, 0, 0, 0.23);
    z-index: 999;
    background-color: #fff;
}

.btn-google-login-landing {
    padding: 0.5rem 0.9rem;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
}

.btn-google-login-landing:hover, .btn-google-login-landing:focus {
    text-decoration: underline;
    outline: 0;
}

.profile-picture {
    margin-top: -2px;
    margin-bottom: 2px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.profile-letter {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: #1b6d85;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.account-dropdown-landing-div {
    margin-top: 2px;
    margin-bottom: -2px;
}

.account-dropdown-landing {
    border-width: 0;
    padding: 0 1.5em 0.0em 0.75rem;
}

.account-dropdown-landing:focus {
    border-width: 0;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
}

.dropdown-item {
    cursor: pointer;
    padding: 0.25rem 3.5rem 0.25rem 1.5rem;
}

.dropdown-item:hover {
    color: #fff;
    background-color: #25c5c4;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    background-color: #25b0af;
}

.dropdown-divider {
    margin: 4px 0;
}

.dropdown-icon {
    margin-right: 1em;
}


.btn-add-spot {
    height: 2.2em;
    border-radius: 1.1em;
    font-weight: bold;
    padding: 0 1.5rem;
    box-shadow: 0 0.2em 0.3em 0 rgba(37, 197, 196, 0.2);
    border: 1px solid #25c5c4;
    background-color: transparent;
    color: #10abac;
}

.btn-add-spot:hover, .btn-add-spot:focus {
    text-decoration: none;
    box-shadow: 0 0.3em 0.4em 0 rgba(37, 197, 196, 0.4) !important;
    background-color: #07cfcc !important;
    color: #fff;
}


.btn-add-spot-disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: default;
}

.btn-add-spot-disabled:hover, .btn-add-spot-disabled:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
}

.login-button{
    padding: 0;
    font-size: 1em;
    line-height: 1;
}

