
.mobile-footer {
    /*margin-left: -15px;*/
    /*margin-right: -15px;*/
    padding: 2em;
    color: #3b3f3f;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.27);
    background-color: #ebebeb;
}

.footer-header {
    color: #3b3f3f;
    font-weight: bolder;
    margin-right: 2em;
}

.footer-hr {
    background: #e7e7e7;
    margin: 1em 0;
}


.mobile-footer a:hover {
    text-decoration: underline;
}


.mobile-footer a:hover {
    text-decoration: underline;
}

.mobile-footer a:hover {
    text-decoration: underline;
}
